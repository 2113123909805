import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import bgImage from 'assets/images/background.jpg';
import apiService from '../../../services/ApiService';
import { AuthContext } from '../../../context/AuthContext';
import axios from 'axios';
import { Box, Button, Typography } from '@mui/material';

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.post('/api/v1/users/signIn', credentials);

      if (response?.status === 200) {
        const userData = response.data.data;
        localStorage.setItem('auth-token', userData.authenticationToken);

        localStorage.setItem('user', JSON.stringify(userData));
        axios.defaults.headers.common['authorization'] = userData.authenticationToken;
        setUser(userData);
        toast.success(response.data.message);
        navigate('/dashboard');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Invalid Credentials';
      toast.error(errorMessage);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <Box
          variant="gradient"
          bgcolor="#c32033"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter Your Email and Password.
          </MDTypography>
        </Box>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                name="email"
                value={credentials.email}
                onChange={handleChange}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                name="password"
                value={credentials.password}
                onChange={handleChange}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" justifyContent="space-between" ml={-1}>
              <MDBox>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>

              <Typography
                component={Link}
                to="/authentication/forgot-password"
                variant="button"
                color="#c32033"
                fontWeight="medium"
                textTransform={'capitalize'}
                textGradient
              >
                Forgot Password?
              </Typography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <Button
                style={{
                  backgroundColor: '#c32033',
                  color: 'white',
                  padding: '1rem',
                }}
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
              >
                sign in
              </Button>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{' '}
                <Typography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="#c32033"
                  fontWeight="medium"
                  textTransform={'capitalize'}
                  textGradient
                >
                  Sign up
                </Typography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
